<template>
  <div v-if="permission.access">
    <div class="row">
      <div class="col-md-8">
        <div class="col-md-12">
          <b-card>
            <div class="row align-items-center">
              <div class="col-md-5">
                <img v-if="format.background" :src="$store.state.base_url + format.background.file" alt=""
                     class="w-100">
              </div>
              <div class="col-md-7">
                <h4><b>Name : </b>{{ program.name }}</h4>
                <p class="mb-0"><b>Format Name : </b>{{ format.name }}</p>
                <p class="mb-0"><b>Registration End Date : </b>{{ program.reg_end_date | date_format }}</p>
                <p class="mb-0">
                  <b>Created At : </b>{{ program.created_at | date_time_format }}
                  <span class="badge" :class="program.status == 1? 'badge-success' : 'badge-danger'">
                  {{ program.status == 1 ? "Active" : "Inactive" }}
                </span></p>
              </div>
            </div>
          </b-card>
        </div>
        <div class="col-md-12">
          <b-card title="Certificate Format Lists" no-body>
            <b-card-body>
              <b-row class="justify-content-between">
                <b-col
                    cols="12"
                    md="1"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                  <Select
                      @on-change="paginateChange"
                      v-model="search.paginate"
                      :clearable="false"
                  >
                  <Option :value="10"> 10</Option>
                  <Option :value="30"> 30</Option>
                  <Option :value="50"> 50</Option>
                  <Option :value="80"> 80</Option>
                  <Option :value="100"> 100</Option>
                  </Select>
                </b-col>
                <b-col cols="12" md="2">
                  <Select
                      v-model="search.status"
                      @on-change="SearchData"
                      :clearable="true"
                      placeholder="Select Status"
                  >
                  <Option :value="1"> Issued</Option>
                  <Option :value="2"> Pending</Option>
                  <Option :value="3" disabled> Rejected</Option>
                  </Select>
                </b-col>
                <b-col cols="12" md="3">
                  <DatePicker
                      format="yyyy-MM-dd"
                      type="date"
                      name="from_date"
                      v-model="search_from_date"
                      id="from_date"
                      placeholder="Issue Date From"
                      :clearable="true"
                      style="width: 100%"
                  ></DatePicker>
                </b-col>
                <b-col cols="12" md="3">
                  <DatePicker
                      format="yyyy-MM-dd"
                      type="date"
                      name="to_date"
                      v-model="search_to_date"
                      id="to_date"
                      placeholder="Issue Date To"
                      :clearable="true"
                      style="width: 100%"
                  ></DatePicker>
                </b-col>
                <b-col cols="12" md="3">
                  <Input
                      v-model="search.search_data"
                      class="d-inline-block mr-1"
                      placeholder="Search name..."
                      type="text"
                  />
                </b-col>
              </b-row>
            </b-card-body>
            <div class="table-responsive">
              <table class="table">
                <thead>
                <tr>
                  <th class="text-center">Sl</th>
                  <th>Request info.</th>
                  <th>Data</th>
                  <th
                      class="text-center"
                  >
                    Status
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(certificateRequest, index) in certificateRequests.data"
                    :key="index"
                    v-if="certificateRequests && certificateRequests.data.length"
                >
                  <td class="align-middle text-center">
                    {{ certificateRequests.from + index }}
                  </td>
                  <td>
                    <p class="mb-0"><b>Email : </b>{{ certificateRequest.email }}</p>
                    <p class="mb-0"><b>Phone : </b>{{ certificateRequest.number }}</p>
                    <p class="mb-0"><b>Start Date : </b>{{ certificateRequest.start_date | date_format }}</p>
                  </td>
                  <td>
                    <p
                        v-for="(data, key) in certificateRequest.data" class="mb-0"
                    >
                      <b>{{ key }} : </b> {{ data }}
                    </p>
                  </td>
                  <td
                      class="align-middle text-center"
                      v-if="permission.edit && certificateRequest.status == 2"
                  >
                    <Tooltip content="Status update" placement="top-end">
                      <button
                          v-if="permission.edit"
                          class="btn btn-success btn-sm"
                          @click="issueCertificate(certificateRequest.id)"
                      >
                        <feather-icon icon="CheckCircleIcon" size="16"/>
                      </button>
                    </Tooltip>
                  </td>
                  <td
                      v-if="permission.edit && certificateRequest.status == 1"
                  >
                    <p class="badge badge-success" style="margin-bottom: 5px"> Issued </p>
                    <p class="mb-0"><b> Certificate ID : </b>{{ certificateRequest.certificate.certificate_id }} </p>
                    <p class="mb-0"><b> Issued by : </b>{{ certificateRequest.issue_by }} </p>
                    <p class="mb-0"><b> Issued date : </b>{{ certificateRequest.issue_date | date_format }} </p>
                  </td>
                </tr>
                <tr v-if="!certificateRequests.data.length" class="text-center">
                  <h3>No data found</h3>
                </tr>
                </tbody>
              </table>
            </div>
            <b-card-body>
              <div class="d-flex justify-content-between align-items-center">
              <span class="text-muted"
              >Showing {{ certificateRequests.from ? certificateRequests.from : 0 }} to
                {{ certificateRequests.to ? certificateRequests.to : 0 }} of
                {{ certificateRequests.total ? certificateRequests.total : 0 }} entries</span
              >
                <pagination
                    :data="certificateRequests"
                    @pagination-change-page="getCertificateRequests"
                    align="right"
                    :show-disabled="true"
                    :limit="5"
                ></pagination>
              </div>
            </b-card-body>
          </b-card>
        </div>
      </div>
      <div class="col-md-4">
        <b-card>
          <b-card-title>Add Meta Data</b-card-title>
          <Button v-for="(meta, index) in meta_data" v-if="meta_data.length" :key="index" @click="addMetaData(meta)"
                  :type="CheckMetaData(meta.id) == true ? 'primary' : 'default'" style="margin-right: 5px">
            {{ meta.name }}
          </Button>
          <div class="row mt-1" v-for="(data, index) in form.data" :key="index + 'data'" v-if="form.data.length">
            <div class="col-md-3">
              <Input disabled v-model="data.slug"/>
            </div>
            <div class="col-md-9">
              <Input placeholder="Enter Your Meta Value" v-model="data.value"/>
            </div>
          </div>

          <Button type="success" @click="saveData" class="w-100 mt-1">
            Save
          </Button>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardTitle,
  BRow,
  BCol,
  BFormCheckbox,
  BForm,
  BButton,
  BFormFile,
} from "bootstrap-vue";
import {HasError} from "vform/src/components/bootstrap5";
import _ from "lodash";

export default {
  data() {
    return {
      form: {
        meta_id: [],
        data: [],
      },
      search: {
        search_data: "",
        status: "",
        from_date: "",
        to_date: "",
        paginate: 10,
        page: 1,
      },
      search_from_date: null,
      search_to_date: null,
      program: {},
      certificateRequests: {},
      format: {},
      meta_data: {},
      id: this.$route.params.program_id
    };
  },
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BRow,
    BCol,
    BFormCheckbox,
    BForm,
    BButton,
    HasError,
    BFormFile,
  },
  mounted() {
    this.getResults();
    this.getProgramData();
    this.getCertificateRequests();
  },
  methods: {
    getCertificateRequests(page) {
      this.search.page = page == null ? this.search.page : page;
      this.search.from_date = this.formatDate(this.search_from_date);
      this.search.to_date = this.formatDate(this.search_to_date);
      const queryParams = this.buildQueryParamString(this.search);
      axios
          .get(`/app/certificate/request/list/` + this.$route.params.program_id + queryParams)
          .then((res) => {
            this.certificateRequests = res.data.certificateRequestLists;
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    issueCertificate(id) {
      axios
          .post(`/app/certificate/issue/` + this.$route.params.program_id + `/` + id)
          .then((res) => {
            this.s(res.data.message)
            this.getCertificateRequests()
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    addMetaData(meta) {
      if (this.form.meta_id.includes(meta.id)) {
        const index = this.form.meta_id.indexOf(meta.id);
        let data_index = this.form.data.findIndex(data => data.slug == meta.slug);
        this.removeMetaData(index, data_index);
      } else {
        this.form.meta_id.push(meta.id);
        this.form.data.push({
          slug: meta.slug,
          value: meta.default_data,
        });
      }
    },

    removeMetaData(index, data_index) {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, remove it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.form.meta_id.splice(index, 1);
          this.form.data.splice(data_index, 1);
        }
      });
    },

    CheckMetaData(id) {
      return this.form.meta_id.includes(id);
    },

    getResults() {
      axios
          .get(`/app/program/show/` + this.$route.params.program_id)
          .then((res) => {
            this.program = res.data.program;
            this.format = res.data.format;
            this.meta_data = res.data.format.meta_data;
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    getProgramData() {
      axios
          .get(`/app/program/data/` + this.$route.params.program_id)
          .then((res) => {
            this.form.meta_id = res.data.meta_id;
            this.form.data = res.data.data;
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    saveData() {
      axios
          .put(`/app/program/data/` + this.$route.params.program_id, this.form)
          .then((res) => {
            this.s(res.data.message)
            this.getProgramData()
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    formatDate(date) {
      if (date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      }
      return null;
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },
  },
  computed: {
    permission() {
      return this.$store.getters.getPermissions.program;
    },
  },
  watch: {
    "search.search_data": function () {
      this.SearchData();
    },
    "search.status": function () {
      this.SearchData();
    },
    "search_from_date": function () {
      this.SearchData();
    },
    "search_to_date": function () {
      this.SearchData();
    },
    "search.paginate": function () {
      this.paginateChange();
    },
  },
};
</script>
